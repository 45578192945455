<template>
  <el-dialog :destroy-on-close="true" center :visible.sync="visible"  height="510px" @close="closeFn" ref="diaRef">
   <div class="dialog-box">
     <div class="left-img">
       <div class="title">特殊作业票管理系统</div>
       <div class="middle">
         <div class="middle-text">
           <span>安全规范</span>
         </div>
         <div class="middle-text">
           <span>高效好用</span>
         </div>
         <div class="middle-text">
           <span>灵活可配</span>
         </div>
         <div class="middle-text">
           <span>符合监管</span>
         </div>
       </div>
       <div class="contact">
         <img src="@/assets/img/telephone.png" alt="">
         <span>400-600-7966</span>
       </div>
     </div>
     <div class="right-form">
       <template v-if="show == '1'">
         <div style="width:80%;height: 100%;">
          <iframe id="24594812290b4e44a502098883432479" width="100%" height="100%" frameborder="0" src="https://app.swhudong.com/apply/24594812290b4e44a502098883432479" onload="this.style.height='100%'"
          ref="frameRef"
          ></iframe>
         </div>

       </template>
       <template v-else="show">
         <div class="success-box">
           <img src="@/assets/img/zu.png" alt="">
           <div class="text-1">提交成功</div>
           <div class="text-2">我们会在3个工作日内与您联系,请注意接听来电</div>
           <el-button class="btn" type="primary" @click="successFn">好的</el-button>
         </div>
       </template>
     </div>
   </div>

  </el-dialog>
</template>
<script>
import {getPhoneCode, saveForm, getCompanyName} from '@/utils/api'

export default {
  name: '',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    frameId: {
      type: String,
      default: '',
    },
  },
  watch: {
    dialogVisible: function (nelVal, oldVal) {
      this.visible = nelVal
    }
  },

  data() {
    return {
      form: {
        registerProduct: '特殊作业票管理系统',
        interestProduct: '特殊作业票管理系统',
        concreteDemand: '想要了解此产品'
      },
      rules: {
        companyname: [{required: true, message: '请输入单位名称', trigger: 'change'},],
        phone: [{required: true, message: '请输入联系方式', trigger: 'blur'},],
        checkCode: [{required: true, message: '请输入验证码', trigger: 'blur'},],
      },
      show: '1',
      showCode: true,
      timeNum: 60,
      timeOut: '',
      debounce: 500,
      visible: false,
      showUrl:''
    }
  },
  methods: {
    closeFn() {
      this.form = {}
      this.frameId = '';
      this.url = '';
      this.$emit('update:dialogVisible', false)

      // this.$refs['formRef'].clearValidate()
    },
    //获取验证码
    sendCode() {
      let that = this
      let num = that.form.phone
      var pattern = /0?(13|14|15|17|18|19)[0-9]{9}/
      let result = pattern.test(num)
      if (!result) {
        that.$message.warning('无效手机号')
      } else {
        let data = {}
        data.phoneNumber = that.form.phone
        getPhoneCode(data).then(res => {
          that.timeNum = 60
          that.showCode = false
          clearInterval(that.timeOut)
          that.timeOut = setInterval(() => {
            if (that.timeNum == 1) {
              that.showCode = true
            }
            that.timeNum--

          }, 1000)
        })

      }

    },
    sendFormFn() {
      let that = this
      /* that.showCode = true
      that.form = {}
      that.show = '2' */
      that.$refs['formRef'].validate((valid) => {
        if (valid) {
          saveForm(that.form).then(res => {
            if (res.data == '注册成功') {
              clearInterval(that.timeOut)
              that.showCode = true
              that.form = {}
              that.show = '2'
            } else {
              that.$message.warning(res.data)
            }
          }).catch(err => {
            that.$message.error(err)
          })
        } else {
          return false
        }
      })

    },
    handleSelect(value) {
      this.form.companyname = value.name
    },
    successFn() {
      this.show = '1'
      this.closeFn()
    },
    querySearchAsync(data, cb) {
      if (data) {
        getCompanyName({word: data}).then(res => {
          if (res.status == 200) {
            if (res.data.result) {
              let arr = res.data.result.items
              let newHtml = `<span style="color:brown">${data}</span>`
              arr.forEach(item => {
                item.value = item.name.replace(data, newHtml)
              })
              cb(arr)
            } else {
              console.log('没有数据');
            }
          } else {
            this.$message.warning(res.msg)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    }
  }
}

</script>
<style scoped lang="less">
.dialog-box {
  display: flex;
  height: 548px;
}
::v-deep .el-dialog {
    border-radius: 9px;
}

.middle {
  font-size: 18px;
  margin-bottom: 45px;

  img {
    width: 18px;

  }

  .middle-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 190px;

    img {
      width: 16px;
      height: 16px;
      position: relative;
      top: 9px;
    }
  }
}

::v-deep .el-dialog__headerbtn {
  font-size: 21px;
  top: 7px;
  right: 10px;
}

.contact {
  font-size: 20px;

  img {
    width: 19px;
    margin-right: 10px;

  }
}

.left-img {
  color: #fff;
  padding: 125px 30px 110px 38px;
  box-sizing: border-box;
  background-image: url(../assets/img/blue.png);
  background-size: 100% 100%;

  .title {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 45px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 33px;
    // 强制不换行
    white-space: nowrap;
  }

}

.right-form {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .title {
    text-align: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 25px;
    color: #018DFF;
  }

  .el-button {
    width: 100%;
    margin-top: 30px;
  }

  span {
    font-size: 14px;
    cursor: pointer;
    color: #186CF5;
  }

  .start-btn {
    height: 54px;
    background: linear-gradient(90deg, #018DFF 0%, #1A74F1 100%);
    //box-shadow: 0px 3px 6px 1px rgba(4, 61, 219, 0.34);
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 16px;
  }

  .start-btn:hover {
    background: -webkit-gradient(linear, left top, right top, from(#186cf5), to(#095ee9));
    background: linear-gradient(90deg, #186cf5, #095ee9);
  }

  .success-box {
    text-align: center;

    img {
      width: 100px;
    }

    .text-1 {
      font-size: 24px;
      color: #202124;
      margin-top: 30px;
      margin-bottom: 20px;
      box-sizing: border-box;

    }

    .text-2 {
      font-style: 16px;
      color: #202124;
      margin-bottom: 40px;
      box-sizing: border-box;

    }

    .btn {
      background: linear-gradient(90deg, #018DFF 0%, #1A74F1 100%);
      border-radius: 5px 5px 5px 5px;
      width: 110px;
      height: 46px;
    }

  }
}

::v-deep .el-form-item__label {
  height: 34px;
  margin-bottom: 6px;
}
::v-deep .el-dialog{
  background: transparent;
  box-shadow: none
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 0 !important
}


.el-autocomplete {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-form-item__error {
  display: none;
}

::v-deep .el-input__suffix {
  right: 10px;
}
</style>
