<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="pc_page">
      <FhNavigation class="fhnav"></FhNavigation>

      <div class="banner-div">

        <!-- <div class="jxd-navbar">
      
          <div>
            <div class="nav-logo">
              <a href>
                <img src="@/assets/img/jxdbai.png" alt />
              </a>
            </div>
            <div class="stock-box">
              <span class="stock-name">股票名称: 金现代</span>
              <span class="stock-code">股票代码: 300830</span>
            </div>
          </div>
        
          <div>
            <span class="jxd-contact">400-600-7966</span>
            <el-button class="nav-btn" @click="openDialog('top')">预约体验</el-button>
          </div>
        </div> -->
        <div class="main-width-top banner">
          <!-- 左侧文字介绍 -->
          <div class="left-text" style="margin-top: 70px;">
            <h2 class="left-title">特殊作业票管理系统</h2>
            <div class="left-bottom">
              “拖拉拽、搭积木”式绘制作业票与流程<br />
              实现零代码变更票面与流程
            </div>
            <div class="bottom-btn" style="text-align: left;">
              <el-button class="liner-btn" type="primary" @click="openDialog('banner')">预约体验</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="pain-spot-div">
        <div class="main-width">
          <div class="title pain-spot-title" style="text-align: center;">
            传统作业票痛点
          </div>
          <div class="card-parent">
            <div v-for="(item, index) in painSpotList" :key="index" class="pain-spot-card"
              @mouseenter="getBlurPainSpot(index)" @mouseleave="mouseleavePainSpot()">
              <div>
                <img v-if="index == blurPainSpot" :src="item.selectUrl" alt width="40" />
                <img v-else :src="item.url" alt width="40" />
              </div>
              <div class="card-title">
                {{ item.title }}
              </div>
              <div class="card-text">
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button class="liner-btn" type="primary" @click="openDialog('pain')">预约体验</el-button>
          </div>
        </div>
      </div>
      <div class="system-div">
        <div class="system-diagram-bg">
          <div class="main-width">
            <div class="title system-title" style="text-align: center;margin-bottom: 20px;">
              特殊作业票管理系统
            </div>
            <div class="system-introduce">
              提供票面可视化编辑器，支持用户通过拖拉拽的方式自定义编辑作业票票面及流程<br />
              内置8大特殊作业票面，符合国标要求，实现预约、申请、审批到验收归档的全过程数字化，规范化管理
            </div>
            <div class="center-div">
              <div class="center-side-text left-side-text">
                <div>功能齐全、稳定</div>
                <div>成熟产品&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>远程部署&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>满足政府要求</div>
              </div>
              <div class="img-div">
                <img src="@/assets/img/extendAnimation.svg" alt />
              </div>
              <div class="center-side-text right-side-text">
                <div>拖拉拽调整票面</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;辅助导入基础数据</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;无需需求调研</div>
                <div>一周即可顺畅使用</div>
              </div>
            </div>
            <div class="line-bg">
              <div class="prepare-div">
                <div class="stage-div">
                  准备阶段
                </div>
                <div class="prepare-bottom">
                  <div class="bottom-text">
                    <span class="small-div">电子签名</span>&nbsp;&nbsp;
                    <span class="small-div">无纸化流转</span>&nbsp;&nbsp;
                    <span class="small-div">可视化编辑</span>
                  </div>
                  <div class="bottom-bottom">
                    <div class="bottom-right">作业预约</div>
                    <div class="bottom-right">作业申请</div>
                    <div class="bottom-right">作业审批</div>
                  </div>
                </div>
              </div>
              <div class="task-div">
                <div class="stage-div">
                  作业阶段
                </div>
                <div class="prepare-bottom">
                  <div class="bottom-text">
                    <span class="small-div">数据随时上报</span>&nbsp;&nbsp;
                    <span class="small-div">指纹人脸识别</span>&nbsp;&nbsp;
                    <span class="small-div">作业一张图</span>
                  </div>
                  <div class="bottom-bottom">
                    <div class="bottom-flex">
                      <div class="bottom-right">气体分析管理</div>
                      <div class="bottom-right">监护人变更管理</div>
                    </div>
                    <div class="bottom-flex">
                      <div class="bottom-right">安全措施管理</div>
                      <div class="bottom-right">安全交底台账</div>
                    </div>
                    <div class="bottom-flex">
                      <div class="bottom-right">作业中断/恢复</div>
                      <div class="bottom-right">地图服务配置</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="prepare-div">
                <div class="stage-div">
                  验收阶段
                </div>
                <div class="prepare-bottom">
                  <div class="bottom-text">
                    <span class="small-div">电子化存档</span>&nbsp;&nbsp;
                    <span class="small-div">随时查阅</span>&nbsp;&nbsp;
                    <span class="small-div">追溯安全便捷</span>
                  </div>
                  <div class="bottom-bottom">
                    <div class="bottom-right">作业验收</div>
                    <div class="bottom-right">作业查询</div>
                    <div class="bottom-right">作业统计</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-btn">
              <el-button class="liner-btn" type="primary" @click="openDialog('work')">预约体验</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="function-div" :class="activeNamePC == 1 ? 'process-function-div' : ''">
        <div class="main-width">
          <div class="title function-title" style="text-align: center;">
            产品主要功能
          </div>
          <div class="function-describe" style="text-align: center;">
            帮助企业规范施工作业安全管控工作，规避作业过程中潜在的风险，保障作业人员的安全
          </div>
          <div class="function-bottom">
            <el-tabs v-model="activeNamePC" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in functionList" :key="index">
                <div slot="label" class="label-div">
                  <div>
                    <img :src="item.url" alt="">
                  </div>
                  <div class="tab-label" :class="index == activeNamePC ? 'tab-label-active' : ''">
                    {{ item.label }}
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div v-for="(item, index) in functionDetailList" :key="index" v-if="index == activeNamePC" class="tab-body">
              <div class="tab-left">
                <div class="title-div">{{ item.title }}</div>
                <div v-if="index != 1" class="function-introduce">{{ item.introduce }}</div>
                <div v-if="index == 1" class="function-introduce process-introduce">{{ item.introduce }}</div>
                <div v-if="index == 1" class="process-step">
                  <div v-for="(i, ind) in operationProcess" :key="ind" @mouseenter="mouseEnterProcess(ind)"
                    @mouseleave="mouseleaveProcess()">
                    <div v-if="ind == activeProcess" class="active-div">
                      <div>
                        <img src="@/assets/img/selectBlue.png" alt="">
                      </div>
                      <div class="process-card">
                        <div class="process-card-title">
                          {{ i.title }}
                        </div>
                        <div class="process-explain">
                          {{ i.explain }}
                        </div>
                      </div>
                    </div>
                    <div v-else class="not-active-div">
                      <img src="@/assets/img/defaultGrayBlue.png" alt="">
                      <span class="process-title">{{ i.title }}</span>
                    </div>
                  </div>
                </div>
                <div :class="index == 1 ? 'process-btn-div' : ''">
                  <el-button class="liner-btn" type="primary" @click="openDialog('function')">预约体验</el-button>
                </div>
              </div>
              <div class="tab-right">
                <img :src="item.url" alt="" v-if="index != 1 && index != 0" width="100%">
                <img :src="operationProcess[activeProcess].url" alt="" v-if="index == 1" width="100%">
                <!--视频窗口展示-->
                <div id="playWnd" class="playWnd" v-if="index == 0">
                  <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                    :options="playerOptions">
                  </video-player>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-div">
        <div class="main-width">
          <div class="title more-title" style="text-align: center;">
            更多产品特性
          </div>
          <div class="more-card-parent">
            <div v-for="(item, index) in characteristicList" :key="index" class="more-card">
              <div>
                <img :src="item.url" alt width="52" />
              </div>
              <div class="more-card-title">
                {{ item.title }}
              </div>
              <div class="more-card-text">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage-div">
        <div class="main-width advantage-bg">
          <div class="advantage-top">
            <div class="advantage-text">
              <div class="advantage-title">
                我们的企业优势
              </div>
              <div class="advantage-introduce">
                金现代（股票代码：300830）成立于2001年，是国内领先的行业数字化解决方案提供商，在北京、上海、广州、南京等10余个地区设有子公司，产品和服务遍布全国22个省、5个自治区、4个直辖市。公司是国家鼓励的重点软件企业，通过了CMMI5级软件成熟度国际认证、ITSS信息技术服务运行维护三级认证。
              </div>
              <el-button class="graspBtn" type="primary" @click="understandJxd()">了解金现代<i class="el-icon-arrow-right"></i>
              </el-button>
              <!--            <div>-->
              <!--              <img src="@/assets/img/understand-jxd.png" alt="" width="138" @click="understandJxd()"-->
              <!--                   class="understand-jxd-img">-->
              <!--            </div>-->
            </div>
            <div class="advantage-img">
              <img src="@/assets/img/advantage.png" alt="">
            </div>
          </div>
          <div class="advantage-bottom">
            <div class="advantage-item">
              <div class="advantage-tip">服务世界500强企业</div>
              <div class="advantage-num">30+</div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="advantage-item">
              <div class="advantage-tip">定制化经验</div>
              <div class="advantage-num">20年+</div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="advantage-item">
              <div class="advantage-tip">典型项目成功案例</div>
              <div class="advantage-num">3000+</div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="advantage-item">
              <div class="advantage-tip">研发人员比例</div>
              <div class="advantage-num">85%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="service-div">
        <div class="main-width">
          <div class="title service-title" style="text-align: center;">
            我们致力于为您提供专业、优质的服务
          </div>
          <div class="step-div">
            <div v-for="(item, index) in serviceList" :key="index" class="step-card"
              :class="index % 2 == 0 ? 'bottom-step-card' : ''">
              <div class="service-img-div">
                <img :src="item.url" alt="" width="188">
              </div>
              <div class="step-title-div" :class="index == 4 ? 'last-step-describe-div' : ''">
                <span class="serial-number-span">{{ item.serialNumber }}</span>
                <span class="step-title">{{ item.title }}</span>
              </div>
              <div v-for="(i, ind) in item.describe" :key="ind" class="step-describe-div"
                :class="index == 4 ? 'last-step-describe-div' : ''">
                <img src="@/assets/img/tick.svg" alt="">
                <span class="step-describe">{{ i }}</span>
              </div>
            </div>
          </div>
          <div class="service-bottom-btn" style="text-align: center;">
            <el-button class="liner-btn" type="primary" @click="openDialog('default')">预约体验</el-button>
          </div>
        </div>
      </div>
      <div class="experience-div" style="text-align: center;">
        <div class="main-width">
          <div class="experience-title">
            立即体验
          </div>
          <div class="further-div">
            进一步了解特殊作业票管理系统解决方案
          </div>
          <top :title="false" :inline="true" :form-style="false"></top>
        </div>
      </div>
      <FhBottom></FhBottom>

      <!-- <div class="jxd-bottom">
        <span>© 2016-2022 金现代信息产业股份有限公司</span>
        <span>鲁ICP备09033671</span>
        <span @click="toPolice()" class="record-no">
          <img src="@/assets/img/gongan.png" alt="">
          <span class="record-no-span">鲁公网安备 37010102000748号</span>
        </span>
      </div> -->
      <!-- form弹框 -->
      <formDialog ref="formDiaRef" :dialogVisible.sync="dialogVisible" :url="dialogUrl" :frameId="frameId"></formDialog>
      <div class="right-button-div">
        <div class="free-experience-div" @click="openDialog('right')">
          <div><img src="@/assets/img/free-experience.png" alt="" width="26"></div>
          <div class="button-text">免费体验</div>
        </div>
        <el-divider></el-divider>
        <div @mouseenter="isShowPhone = true" @mouseleave="isShowPhone = false">
          <div><img src="@/assets/img/consult.png" alt="" width="26"></div>
          <div class="button-text">电话咨询</div>
        </div>
        <el-divider></el-divider>
        <div @mouseenter="isShowCode = true" @mouseleave="isShowCode = false">
          <div><img src="@/assets/img/wechat.png" alt="" width="29"></div>
          <div class="button-text">微信咨询</div>
        </div>
      </div>
      <div class="arrow-div" @click="toTop()" v-if="isTop">
        <img src="@/assets/img/top-arrow.png" alt="" width="73">
      </div>
      <div class="phone-div" v-if="isShowPhone">
        <div><img src="@/assets/img/small-phone.png" alt="" width="17"></div>
        <div class="phone-text">400-600-7966</div>
      </div>
      <div class="qr-code-div" v-if="isShowCode">
        <div><img src="@/assets/img/we-com.png" alt="" width="135"></div>
        <div class="qr-code-text">获取专业解决方案</div>
      </div>
    </div>
    <div class="app_page">
      <div class="jxd-navbar">
        <!-- logo部分 -->
        <div class="top_box">
          <div class="nav-logo">
            <a href>
              <img src="@/assets/img/app_adaptation/jxdlan.png" alt height="30" />
            </a>
          </div>
          <div class="stock-box">
            <div class="stock-name">股票名称: 金现代</div>
            <div class="stock-code">股票代码: 300830</div>
          </div>
        </div>
      </div>
      <div class="app_content">
        <div class="banner-div">
          <div class="main-width-top banner">
            <!-- 左侧文字介绍 -->
            <div class="center-text">
              <h2 class="left-title">特殊作业票管理系统</h2>
              <div class="left-bottom">
                “拖拉拽、搭积木”式绘制作业票与流程<br />
                实现零代码变更票面与流程
              </div>
              <div class="app_bdzy">
                <img src="@/assets/img/app_adaptation/app_bdzy.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="pain-spot-div">
          <div class="title pain-spot-title" style="text-align: center;">
            产品价值
          </div>
          <div>
            <img src="@/assets/img/app_adaptation/app_zjxtt.png" width="90%" />
          </div>
          <div class="product_content">
            <div class="flex-around">
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">“拖拉拽”</div>
                  <div class="product_text">自主调整票面</div>
                </div>
              </div>
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">低代码开发</div>
                  <div class="product_text">定制化能力强</div>
                </div>
              </div>
            </div>
            <div class="flex-around">
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">产品成熟</div>
                  <div class="product_text">7天快速交付</div>
                </div>
              </div>
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">功能齐全</div>
                  <div class="product_text">系统运行稳定</div>
                </div>
              </div>
            </div>
            <div class="flex-around">
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">预留接口</div>
                  <div class="product_text">满足监管要求</div>
                </div>
              </div>
              <div class="product_box">
                <div class="polka_dot"></div>
                <div>
                  <div class="product_text">远程部署</div>
                  <div class="product_text">专属服务团队</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="function-div" :class="activeName == 1 ? 'process-function-div' : ''">
          <div class="title function-title" style="text-align: center;">
            产品主要功能
          </div>
          <div class="function-bottom">
            <van-tabs v-model="activeName" @tab-click="handleClick" swipeable :ellipsis="false" line-width="70px"
              color="rgba(61, 118, 246, 1)">
              <van-tab v-for="(item, index) in functionListApp" :key="index">
                <template #title>
                  <div slot="label" class="label-div">
                    <div>
                      <img :src="item.urlApp" alt="" width="33">
                    </div>
                    <div class="tab-label" :class="index == activeName ? 'tab-label-active' : ''">
                      {{ item.label }}
                    </div>
                  </div>
                </template>
                <div>
                  <div class="tab-left">
                    <div v-if="index != 1" class="function-introduce">{{ item.introduce }}</div>
                    <div v-if="index == 1" class="function-introduce process-introduce">{{ item.introduce }}</div>
                    <van-steps v-if="index == 1" :active="activeProcess" active-icon="app_active"
                      inactive-icon="app_inactive" active-color="#3D76F6" @click-step="mouseEnterProcess">
                      <van-step v-for="(i, ind) in operationProcess" :key="ind">{{ i.title }}</van-step>
                    </van-steps>
                  </div>
                  <div class="tab_content">
                    <img :src="item.urlContentUrl" alt="" v-if="index != 1 && index != 0" width="100%">
                    <img :src="operationProcess[activeProcess].urlApp" alt="" v-if="index == 1" width="100%">
                    <!--视频窗口展示-->
                    <div id="playWnd" class="playWnd" v-if="index == 0">
                      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                        :options="playerOptions">
                      </video-player>
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </div>
        <div class="more-div">
          <div class="title more-title" style="text-align: center;">
            更多产品特性
          </div>
          <div class="more-card-parent">
            <div v-for="(item, index) in characteristicList" :key="index" class="more-card">
              <div>
                <img :src="item.url" alt width="30" />
              </div>
              <div class="more-card-title">
                <div>{{ item.title1 }}</div>
                <div>{{ item.title2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-div">
          <div class="title service-title" style="text-align: center;">
            <div>我们致力于为您提供</div>
            <div>专业、优质的服务</div>
          </div>
          <div class="service-content">
            金现代(股票代码: 300830) 成立于2001年，是国内领先的行业数字化解决方案提供商，公司是国家鼓励的重点软件企业。
          </div>
          <div class="supply_box">
            <van-grid :column-num="3">
              <van-grid-item v-for="(item, index) in serviceList" :key="index"
                :style="index + 1 > Math.floor(serviceList.length / 3) * 3 ? 'flex-basis:50%;' : ''">
                <div class="service-img-div">
                  <img :src="item.appUrl" alt="" height="38">
                </div>
                <div class="step-title-div" :class="index == 4 ? 'last-step-describe-div' : ''">
                  <span class="step-title">{{ item.title }}</span>
                </div>
                <div class="step-describe">{{ item.appDescribe }}</div>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
        <div class="experience-div">
          <div class="further-div" style="text-align: center;">
            进一步了解特殊作业票管理系统解决方案
          </div>
          <div class="left-text">
            <el-button class="liner-btn" type="primary" @click="openAppDialog">开始体验</el-button>
          </div>
        </div>
        <div class="jxd-bottom">
          <div class="connect_box">
            <div class="connect_text">
              <img src="@/assets/img/app_adaptation/phone.svg" width="12" />
              服务热线：400-600-7966
            </div>
            <div class="connect_text">
              <img src="@/assets/img/app_adaptation/position.svg" width="12" />
              公司地址：济南市高新区新泺大街1166号奥盛大厦2号楼
            </div>
          </div>
          <van-divider :style="{ borderColor: 'rgba(255, 255, 255, 0.2)' }" />
          <div class="remark">
            <span>© 2016-2022 金现代信息产业股份有限公司</span>
            <span>鲁ICP备09033671</span>
            <span @click="toPolice()" class="record-no">
              <span class="record-no-span">鲁公网安备 37010102000748号</span>
            </span>
          </div>
        </div>
        <!-- form弹框 -->
        <formAppDialog :dialogVisible.sync="dialogAppVisible"></formAppDialog>
      </div>
      <div class="locate_btn flex-center" style="text-align: center;">
        <a class="phont_btn" @click="callPhone" ref="telPhone">电话咨询 400-600-7966</a>
        <div class="go_btn" @click="openAppDialog">立即体验</div>
      </div>
    </div>
  </div>
</template>


<script>
import top from '@/components/TopForm.vue'
import formDialog from '@/components/dialogForm.vue'
import formAppDialog from '@/components/dialogAppForm.vue'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'Home',
  metaInfo: {
    title: '作业票_特殊作业票管理系统_电子作业票|金现代作业票系统', // set a title
    meta: [{                 // set meta
      name: 'keyWords',
      content: '特殊作业票管理系统，特殊作业管理系统，电子作业票系统，作业安全管控系统，危险作业智能监控系统'
    },
    {
      name: 'description',
      content: '金现代特殊作业票系统，是一款提供票面可视化编辑器的作业票管理系统，支持用户通过拖拉拽的方式自定义编辑作业票票面及流程，内置8+3种作业票，符合国标要求，实现作业票从预约、申请、审批到验收归档的全过程管理，可以帮助企业更好地规范施工作业安全管控工作，降低或规避作业过程中潜在的风险，保障作业人员的安全。'
    }
    ],
  },
  components: {
    top,
    videoPlayer,
    formDialog,
    formAppDialog
  },
  data() {
    return {
      dialogUrl: '',
      frameId: '',
      iframeList:
      {//顶部导航表单
        top: {
          id: 'd0b86a158b854a7bb7b5fe8756db7755',
          url: 'https://app.swhudong.com/apply/d0b86a158b854a7bb7b5fe8756db7755'
        },
        banner: {
          id: 'bff9e34bc32e4656ba32333d6a5ae213',
          url: 'https://app.swhudong.com/apply/bff9e34bc32e4656ba32333d6a5ae213'
        },
        right: {
          id: 'e274b18224fd497d907532c5162b6e80',
          url: 'https://app.swhudong.com/apply/e274b18224fd497d907532c5162b6e80'
        },
        pain: {
          id: '303e86aa481a467a8e06880c58bc0fa1',
          url: 'https://app.swhudong.com/apply/303e86aa481a467a8e06880c58bc0fa1'
        },
        work: {
          id: '6f57486ce4f645cba7697a3f828b327d',
          url: 'https://app.swhudong.com/apply/6f57486ce4f645cba7697a3f828b327d'
        },
        default: {
          id: '7c22926bb40e44c4a0b7ab32a95799e4',
          url: 'https://app.swhudong.com/apply/7c22926bb40e44c4a0b7ab32a95799e4'
        },
      },
      painSpotList: [{
        url: require('@/assets/img/1blue.svg'),
        selectUrl: require('@/assets/img/1white.svg'),
        title: '纸质手写难辨认',
        content: '纸质作业票，填写工作量大，手填效率低，并且不同字体难以辨认，规范性无法保障'
      }, {
        url: require('@/assets/img/2blue.svg'),
        selectUrl: require('@/assets/img/2white.svg'),
        title: '流程难追溯',
        content: '审批、验收、安全措施确认等时间是否规范，容易出现代签、改签、补签等违规情况，进而难以保障作业流程规范'
      }, {
        url: require('@/assets/img/3blue.svg'),
        selectUrl: require('@/assets/img/3white.svg'),
        title: '现场难管控',
        content: '难以确认现场作业环境是否安全；监护人、安全交底人、审核人员是否到场，现场检查信息掌握不足，可能出现检查不到位'
      }, {
        url: require('@/assets/img/4blue.svg'),
        selectUrl: require('@/assets/img/4white.svg'),
        title: '历史票证难查询',
        content: '政府要求企业作业票至少保存1年，纸质作业票存储难，后期追溯查找难'
      }, {
        url: require('@/assets/img/5blue.svg'),
        selectUrl: require('@/assets/img/5white.svg'),
        title: '票面更新快',
        content: '随着政府监管政策的变更，企业票面也需同步调整，传统作业票系统票面更新研发成本高'
      }], // 传统作业票痛点列表
      characteristicList: [{
        url: require('@/assets/img/parSpecification.svg'),
        title: '票面符合规范，流程严谨科学',
        title1: '票面符合规范',
        title2: '流程严谨科学',
        content: '特殊作业票票面与审批流程等设计符合国标GB-30871规范'
      }, {
        url: require('@/assets/img/abnormal.svg'),
        title: '异常自动提示，填写符合规范',
        title1: '异常自动提示',
        title2: '填写符合规范',
        content: '内置大量的逻辑校验，当填写不符合规范时，系统即可实时提醒'
      }, {
        url: require('@/assets/img/automaticRead.svg'),
        title: '自动读取数据，气体分析结果无需手输',
        title1: '自动读取数据',
        title2: '气体分析结果无需手输',
        content: '便携式气体检测仪数据、LIMS实验室分析数据自动同步读取'
      }, {
        url: require('@/assets/img/maintain.svg'),
        title: '建设数据字典，易于维护',
        title1: '建设数据字典',
        title2: '易于维护',
        content: '后期需求发生变动时，无需修改产品代码，企业可自主维护'
      }, {
        url: require('@/assets/img/dataReport.svg'),
        title: '预留数据接口，数据随时上报',
        title1: '预留数据接口',
        title2: '数据随时上报',
        content: '可依据政府及相关部门要求，及时的将数据报送给相关平台'
      }, {
        url: require('@/assets/img/videoSurveillance.svg'),
        title: '系统可集成，对接人员定位、视频监控',
        title1: '系统可集成',
        title2: '对接人员定位、视频监控',
        content: '支持与人员定位、视频监控等系统集成，掌握作业人员位置及作业现场视频'
      }], // 更多产品特性列表
      blurPainSpot: -1, // 光标移入痛点
      activeProcess: 0, // 光标移入流程
      serviceList: [{
        url: require('@/assets/img/projectConsult2.png'),
        serialNumber: '01',
        title: '项目咨询',
        describe: ['拥有丰富项目经验', '提供实施咨询服务'],
        appUrl: require('@/assets/img/app_adaptation/app_xmzx.png'),
        appDescribe: '项目经验丰富',
      }, {
        url: require('@/assets/img/requestingResearch2.png'),
        serialNumber: '02',
        title: '需求调研',
        describe: ['厂区实地调研', '需求分析评估'],
        appUrl: require('@/assets/img/app_adaptation/app_xqdy.png'),
        appDescribe: '厂区实地调研',
      }, {
        url: require('@/assets/img/progranPlan2.png'),
        serialNumber: '03',
        title: '方案规划',
        describe: ['标准化产品', '定制化产品'],
        appUrl: require('@/assets/img/app_adaptation/app_fagh.png'),
        appDescribe: '标准定制化产品',
      }, {
        url: require('@/assets/img/RDimplementation2.png'),
        serialNumber: '04',
        title: '研发实施',
        describe: ['软件产品部署', '硬件产品部署'],
        appUrl: require('@/assets/img/app_adaptation/app_yfss.png'),
        appDescribe: '软件和硬件产品部署',
      }, {
        url: require('@/assets/img/afterSales2.png'),
        serialNumber: '05',
        title: '售后运维',
        describe: ['专业团队运营维护支持', '7*24小时服务响应'],
        appUrl: require('@/assets/img/app_adaptation/app_shyw.png'),
        appDescribe: '7*24小时服务响应',
      }], // 服务列表
      activeName: '0', // 产品主要功能选中标签
      activeNamePC: '0', // 产品主要功能选中标签
      functionListApp: [{
        urlApp: require('@/assets/img/app_adaptation/app_zypsjq.svg'),
        introduce: '提供“拖拉拽”配置式的可视化页面、流程设计器，无代码快速构建应用。',
        url: '',
        urlContentUrl: '',
        label: '作业票设计器'
      }, {
        urlApp: require('@/assets/img/app_adaptation/app_zyqlc.svg'),
        introduce: '覆盖作业全流程，实现作业过程可控、可查，保障企业安全。',
        url: '',
        urlContentUrl: '',
        label: '作业全流程'
      }, {
        urlApp: require('@/assets/img/app_adaptation/app_zygcsj.svg'),
        introduce: '作业过程关键数据集中存储与展示，实现作业过程中气体分析结果、监护人变更情况、安全交底台账等信息展示，可通过系统随时查询浏览，方便存储追溯，促进特殊作业过程规范化。',
        url: require('@/assets/img/workProcessData0.png'),
        urlContentUrl: require('@/assets/img/app_adaptation/zygcsj.png'),
        label: '作业过程数据'
      }, {
        urlApp: require('@/assets/img/app_adaptation/app_jcpz.svg'),
        introduce: '实现对承包商管理、承包商人员管理、监护人管理、安全措施管理、常用词配置等基本信息的维护，辅助企业实现数据信息化存储。基础数据作为整个业务系统的数据基础，提前维护好后，便于在使用过程中能够快速引用，提高工作效率。',
        url: require('@/assets/img/basicConfiguration0.png'),
        urlContentUrl: require('@/assets/img/app_adaptation/jcpz.png'),
        label: '基础配置'
      }, {
        urlApp: require('@/assets/img/app_adaptation/app_zyyzt.svg'),
        introduce: '将特殊作业信息与厂区地图深度融合，数据以图表可视化的形式直观展现。我们可在一张图上看到特殊作业的数量、所在厂区位置、作业现场视频，作业内容等信息，便于及时掌握现场的作业情况，实现远程监管。',
        url: require('@/assets/img/jobMap0.png'),
        urlContentUrl: require('@/assets/img/app_adaptation/zyyzt.png'),
        label: '作业一张图'
      }],
      functionList: [{
        url: require('@/assets/img/jobTicketDesigner.svg'),
        urlApp: require('@/assets/img/app_adaptation/app_zypsjq.png'),
        label: '作业票设计器'
      }, {
        url: require('@/assets/img/workFlow.svg'),
        urlApp: require('@/assets/img/app_adaptation/app_zyqlc.png'),
        label: '作业全流程'
      }, {
        url: require('@/assets/img/workProcessDataSVG.svg'),
        urlApp: require('@/assets/img/app_adaptation/app_zygcsj.png'),
        label: '作业过程数据'
      }, {
        url: require('@/assets/img/basicConfigurationSVG.svg'),
        urlApp: require('@/assets/img/app_adaptation/app_jcpz.png'),
        label: '基础配置'
      }, {
        url: require('@/assets/img/jobMapSVG.svg'),
        urlApp: require('@/assets/img/app_adaptation/app_zyyzt.png'),
        label: '作业一张图'
      }], // 产品主要功能列表
      functionDetailList: [{
        title: '作业票设计器',
        introduce: '提供“拖拉拽”配置式的可视化页面、流程设计器，无代码快速构建应用。',
        url: ''
      }, {
        title: '作业全流程',
        introduce: '覆盖作业全流程，实现作业过程可控、可查，保障企业安全。',
        url: ''
      }, {
        title: '作业过程数据',
        introduce: '作业过程关键数据集中存储与展示，实现作业过程中气体分析结果、监护人变更情况、安全交底台账等信息展示，可通过系统随时查询浏览，方便存储追溯，促进特殊作业过程规范化。',
        url: require('@/assets/img/workProcessData0.png')
      }, {
        title: '基础配置',
        introduce: '实现对承包商管理、承包商人员管理、监护人管理、安全措施管理、常用词配置等基本信息的维护，辅助企业实现数据信息化存储。基础数据作为整个业务系统的数据基础，提前维护好后，便于在使用过程中能够快速引用，提高工作效率。',
        url: require('@/assets/img/basicConfiguration0.png')
      }, {
        title: '作业一张图',
        introduce: '将特殊作业信息与厂区地图深度融合，数据以图表可视化的形式直观展现。我们可在一张图上看到特殊作业的数量、所在厂区位置、作业现场视频，作业内容等信息，便于及时掌握现场的作业情况，实现远程监管。',
        url: require('@/assets/img/jobMap0.png')
      }], // 下方产品主要功能列表
      operationProcess: [{
        title: '作业预约',
        explain: '填写作业类型、内容、时间等作业基本信息，向作业活动负责人提前报备',
        url: require('@/assets/img/workReserve0.png'),
        urlApp: require('@/assets/img/app_adaptation/zyyy.png'),
      }, {
        title: '作业预约审批',
        explain: '负责人对未来即将执行的特殊作业进行审核，提前预判可能涉及的作业风险',
        url: require('@/assets/img/workAppointmentApproval0.png'),
        urlApp: require('@/assets/img/app_adaptation/zyyysp.png'),
      }, {
        title: '作业申请',
        explain: '线上填报，无纸化流转缩短填报时间，避免错填、漏填等现象，提高工作效率',
        url: require('@/assets/img/workApplication0.png'),
        urlApp: require('@/assets/img/app_adaptation/zysq.png'),
      }, {
        title: '作业审批',
        explain: '对气体分析、安全措施确认、签字确认等过程实行严格管控，流程可灵活配置',
        url: require('@/assets/img/workApproval0.png'),
        urlApp: require('@/assets/img/app_adaptation/zysp.png'),
      }, {
        title: '作业查询',
        explain: '随时查询历史作业票的作业内容、过程、结果等详细信息，规范化管理作业票',
        url: require('@/assets/img/workQuery0.png'),
        urlApp: require('@/assets/img/app_adaptation/zycx.png'),
      }],
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        controls: false, //不显示暂停、声音、进度条组件
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",
          src: require('@/video/designer.mp4')
        }],
        poster: "", //你的封面地址
        notSupportedMessage: '正在加载...', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        /*controlBar : {
          timeDivider : true,//当前时间和持续时间的分隔符
          durationDisplay : true,//显示持续时间
          remainingTimeDisplay : false,//是否显示剩余时间功能
          fullscreenToggle : true  //全屏按钮
        },*/
        // techOrder: ['flash', 'html5']
      },
      dialogVisible: false, //form弹框表单
      isShowPhone: false, // 是否显示电话
      isShowCode: false, // 是否显示二维码
      isTop: false, // 是否显示回到顶部按钮
      dialogAppVisible: false, //app的form弹框表单
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); //添加监听事件
    this.$refs.formDiaRef.$refs.diaRef.rendered = true

  }
  ,
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll); //移除监听事件
  }
  ,
  methods: {
    callPhone() {
      window.location.href = 'tel://400-600-7966'
    },

    // 获取光标移入的痛点
    getBlurPainSpot(index) {
      this.blurPainSpot = index;
    },

    // 光标离开痛点
    mouseleavePainSpot() {
      this.blurPainSpot = -1;
    },

    // 点击产品主要功能标签
    handleClick(tab, event) {
      console.log(tab, event);
    },

    // 获取光标移入的流程
    mouseEnterProcess(index) {
      this.activeProcess = index;
    },
    // 光标离开流程
    mouseleaveProcess() {

    },
    //打开app弹窗
    openAppDialog() {
      this.dialogAppVisible = true;
      this.$nextTick(() => {
        this.dialogAppVisible = false;
      });
    },
    //打开弹框
    openDialog(val) {
      const self = this;
      switch (val) {
        case 'top':
          self.frameId = self.iframeList.top.id;
          self.dialogUrl = self.iframeList.top.url;
          break;
        case 'banner':
          self.frameId = self.iframeList.banner.id;
          self.dialogUrl = self.iframeList.banner.url;
          break;
        case 'right':
          self.frameId = self.iframeList.right.id;
          self.dialogUrl = self.iframeList.right.url;
          break;
        case 'pain':
          self.frameId = self.iframeList.pain.id;
          self.dialogUrl = self.iframeList.pain.url;
          break;
        case 'work':
          self.frameId = self.iframeList.work.id;
          self.dialogUrl = self.iframeList.work.url;
          break;
        case 'default':
          self.frameId = self.iframeList.default.id;
          self.dialogUrl = self.iframeList.default.url;
          break;
      }
      self.dialogVisible = true
    }
    ,

    // 了解金现代
    understandJxd() {
      window.open('http://www.jxdinfo.com:80')
    }
    ,

    // 跳转到公安机关网页
    toPolice() {
      window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010102000748')
    }
    ,

    // 回到顶部
    toTop() {
      let top = document.documentElement.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop); //清除定时器
        }
      }, 10);
    }
    ,

    handleScroll() { //监控scrollTop 的值，根据其变化执行相应操作（回到顶部按钮的显示和隐藏）
      if (document.documentElement.scrollTop >= 1500) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app_page {
  display: none;
}

.banner-div {
  width: 100%;
  height: 610px;
  background: url(../assets/img/banner4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .jxd-navbar {
    margin: 0 auto;
    width: 1200px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-logo {
      float: left;
      width: 128px;
      height: 40px;

      img {
        width: 100%;
      }
    }

    .stock-box {
      color: #fff;
    }

    .stock-box {
      float: left;
      margin-left: 14px;
      text-align: left;
      font-size: 14px;

      .stock-name {
        display: block;
        line-height: 22px;
      }
    }

    .jxd-contact {
      height: 100%;
      font-size: 18px;
      line-height: 32px;
      font-family: "PINGFANG HEAVY"
    }

    .el-button {
      padding: 0;
    }

    .nav-btn {
      background-color: #f19f35;
      border-color: #f19f35;
      margin-left: 45px;
      width: 106px;
      height: 42px;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
    }

    .nav-btn:hover {
      background-color: #f3c715;
      border-color: #f3c715;
    }
  }

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.pain-spot-div {
  width: 100%;
  height: 660px;

  .pain-spot-title {
    padding-top: 89px;
  }

  .card-parent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 51px;
    height: 330px;

    .pain-spot-card {
      transition: all .2s ease;
      width: 19%;
      height: 320px;
      background: #FFFFFF;
      box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
      border-radius: 5px;
      padding: 62px 3% 30px 3%;
      box-sizing: border-box;

      .card-title {
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        color: #2E2E2E;
        text-align: center;
        font-weight: 500;
        margin-top: 15px;
      }

      .card-text {
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #585858;
        line-height: 24px;
        font-weight: 400;
        margin-top: 12px;
        text-align: left;
      }
    }

    .pain-spot-card:hover {
      height: 335px;
      background: url(../assets/img/interact.png);
      background-repeat: no-repeat;
      transform: translateY(-8px);

      .card-title {
        color: #FFFFFF;
      }

      .card-text {
        color: #FFFFFF;
      }
    }
  }

  .bottom-btn {
    text-align: center;
    margin-top: 42px;
  }
}

.system-div {
  width: 100%;
  height: 1335px;
  background: #F5F9FD;

  .system-diagram-bg {
    width: 100%;
    height: 100%;
    background: url(../assets/img/middlePic2.png);
    background-position: center 250px;
    background-repeat: no-repeat;
    background-size: 1410px;

    .system-title {
      padding-top: 89px;
    }

    .system-introduce {
      font-family: SourceHanSansCN-Regular;
      font-size: 18px;
      color: #2E2E2E;
      text-align: center;
      line-height: 30px;
      font-weight: 400;
      margin-top: 11px;
      margin-bottom: 90px;
    }

    .center-div {
      display: flex;

      .center-side-text {
        width: 237px;
        font-family: SourceHanSansCN-Medium;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: 500;
        line-height: 91px;
        padding-top: 25px;
      }

      .left-side-text {
        text-align: right;
      }

      .img-div {
        width: 726px;
      }

      .right-side-text {
        text-align: left;
      }
    }

    .line-bg {
      width: 100%;
      background: url(../assets/img/line.svg);
      background-repeat: no-repeat;
      background-position: center 47px;
      display: flex;
      justify-content: space-between;
      padding-top: 47px;

      .prepare-div {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .prepare-bottom {
          //display: flex;
          //justify-content: space-between;
          width: 100%;
          height: 170px;

          .bottom-text {
            width: 100%;
            margin-bottom: 8px;
            //display: flex;
            //flex-direction: column;
            //justify-content: space-between;

            .small-div {
              width: 100%;
              height: 30px;
              //background: #E5F0FF;
              border-radius: 5px;
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              color: #1C52BC;
              font-weight: 400;
              line-height: 30px;
            }
          }

          .bottom-right {
            background: #FFFFFF;
            box-shadow: 0 2px 8px 0 rgba(18, 59, 107, 0.05);
            border-radius: 5px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: #2E2E2E;
            line-height: 26px;
            font-weight: 400;
            padding: 8px 0;
            margin-bottom: 8px;
            text-align: center;
          }

          .bottom-bottom {
            width: 50%;
            padding: 0 25%;

            .small-div {
              width: 100%;
              height: 30px;
              //background: #E5F0FF;
              border-radius: 5px;
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              color: #1C52BC;
              font-weight: 400;
              line-height: 30px;
            }
          }
        }
      }

      .task-div {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 110px;

        .prepare-bottom {
          //display: flex;
          //justify-content: space-between;
          width: 100%;
          height: 170px;

          .bottom-text {
            width: 100%;
            margin-bottom: 8px;
            text-align: center;
            //display: flex;
            //flex-direction: column;
            //justify-content: space-between;

            .small-div {
              width: 100%;
              height: 30px;
              //background: #E5F0FF;
              border-radius: 5px;
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              color: #1C52BC;
              font-weight: 400;
              line-height: 30px;
            }
          }

          .bottom-right {
            background: #FFFFFF;
            box-shadow: 0 2px 8px 0 rgba(18, 59, 107, 0.05);
            border-radius: 5px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: #2E2E2E;
            line-height: 26px;
            font-weight: 400;
            width: 48.6%;
            box-sizing: border-box;
            text-align: center;
            padding: 8px 0;
          }

          .bottom-bottom {
            width: 68%;
            padding: 0 16%;

            .bottom-flex {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 8px;
            }

            .small-div {
              width: 100%;
              height: 30px;
              //background: #E5F0FF;
              border-radius: 5px;
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              color: #1C52BC;
              font-weight: 400;
              line-height: 30px;
            }
          }
        }
      }

      .stage-div {
        transition: all .2s ease;
        width: 188px;
        height: 107px;
        background: url(../assets/img/technologyStation.svg);
        background-repeat: no-repeat;
        background-position: center;
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        color: #2E2E2E;
        text-align: center;
        font-weight: 500;
        padding-top: 30px;
        box-sizing: border-box;
      }

      .stage-div:hover {
        transform: translateY(-15px);
        color: #186CF5;
      }
    }
  }
}

.function-div {
  width: 100%;
  height: 837px;

  .function-title {
    padding-top: 90px;
  }

  .function-describe {
    font-family: SourceHanSansCN-Regular;
    font-size: 22px;
    color: #2E2E2E;
    font-weight: 400;
    margin-top: 26px;
  }

  /deep/ .el-tabs__nav-wrap {
    height: 107px;
  }

  /deep/ .el-tabs__nav {
    height: 107px;
    width: 100%;
  }

  /deep/ .el-tabs__item {
    width: 20%;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #3D76F6;
    height: 4px;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #E4E7EC;
  }

  .function-bottom {
    margin-top: 57px;

    ::v-deep .van-tabs--line .van-tabs__wrap {
      height: 80px;
    }

    ::v-deep .van-icon-app_active:before {
      content: url("~@/assets/img/app_adaptation/active.png");
    }

    ::v-deep .van-icon-app_inactive:before {
      content: url("~@/assets/img/app_adaptation/inactive.png");
    }

    .label-div {
      line-height: 22px;

      .tab-label {
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: 500;
      }

      .tab-label-active {
        color: #3D76F6;
      }
    }

    .tab-body {
      display: flex;

      .tab-left {
        width: 48%;
        text-align: left;

        .title-div {
          font-family: SourceHanSansCN-Bold;
          font-size: 24px;
          color: #2E2E2E;
          font-weight: 700;
          margin-top: 25px;
        }

        .function-introduce {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          color: #585858;
          line-height: 26px;
          font-weight: 400;
          margin-top: 18px;
          padding-right: 15%;
          height: 253px;
        }

        .process-introduce {
          height: 38px;
        }

        .process-step {
          background: url(../assets/img/dottedLine.png);
          background-repeat: no-repeat;
          background-position: 8px 25px;
          background-size: 3px 225px;
          height: 280px;

          .active-div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .process-card {
              background: #FFFFFF;
              box-shadow: 0px 1px 12px 0px rgba(178, 178, 178, 0.24);
              border-radius: 5px;
              width: 90%;
              padding: 10px 2%;

              .process-card-title {
                font-family: SourceHanSansCN-Medium;
                font-size: 18px;
                color: #3D76F6;
                font-weight: 500;
              }

              .process-explain {
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #585858;
                line-height: 20px;
                font-weight: 400;
                margin-top: 3px;
              }
            }
          }

          .not-active-div {
            height: 54px;
            line-height: 54px;
            padding-left: 4px;

            .process-title {
              font-family: SourceHanSansCN-Medium;
              font-size: 18px;
              color: #2E2E2E;
              font-weight: 500;
              margin-left: 6%;
            }
          }
        }

        .process-btn-div {
          margin-top: 15px;
        }
      }

      .tab-right {
        width: 52%;
        margin-top: 30px;

        .playWnd {
          width: 100%;
          height: 100%;
          margin: 0;
          box-shadow: 0 2px 8px 0 rgba(18, 59, 107, 0.05);
        }
      }
    }
  }
}

.process-function-div {
  height: 910px;
}

.more-div {
  width: 100%;
  height: 700px;
  background: url(../assets/img/bj2.jpg);
  background-position: center;
  background-repeat: no-repeat;

  .more-title {
    color: #FFFFFF;
    padding-top: 90px;
  }

  .more-card-parent {
    width: 100%;
    height: 445px;
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;

    .more-card {
      transition: all .2s ease;
      width: 32%;
      height: 210px;
      background: rgba(255, 255, 255, 0.92);
      border-radius: 5px;
      padding: 27px 19px 20px 23px;
      box-sizing: border-box;
      text-align: left;

      .more-card-title {
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 8px;
      }

      .more-card-text {
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: #2E2E2E;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .more-card:hover {
      background: #FFFFFF;
      box-shadow: 0 3px 6px 0 rgba(1, 58, 115, 0.32);
      transform: translateY(-15px);
    }
  }
}

.advantage-div {
  width: 100%;
  height: 750px;
  background: #F5F9FD;
  padding-top: 86px;
  padding-bottom: 77px;
  box-sizing: border-box;

  .advantage-bg {
    background: url(../assets/img/map.png);
    background-repeat: no-repeat;
    background-position: left bottom;

    .advantage-top {
      display: flex;
      justify-content: space-between;

      .advantage-text {
        width: 45%;
        text-align: left;

        .advantage-title {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 48px;
          color: #202124;
        }

        .advantage-introduce {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 34px;
          color: #2E2E2E;
          margin-top: 39px;
          margin-bottom: 48px;
        }

        .understand-jxd-img {
          cursor: pointer;
        }

        ::v-deep .el-button--primary {
          background-color: #186CF5;
        }

        ::v-deep .el-button--primary:hover,
        ::v-deep .el-button--primary:focus {
          background-color: #1584F3;
          border-color: #1584F3;
        }

        .graspBtn {
          font-size: 14px;

          i.el-icon-arrow-right {
            border-radius: 20px;
            padding: 6px;
            margin-left: 10px;
            background-color: #FFFFFF;
            color: #999999;
          }
        }

        .graspBtn:hover {
          opacity: 0.9;
        }
      }

      .advantage-img {
        width: 43%;
      }
    }

    .advantage-bottom {
      display: flex;
      margin-top: 118px;

      .advantage-item {
        width: 15%;
        text-align: left;

        .advantage-tip {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 35px;
          color: #2E2E2E;
        }

        .advantage-num {
          font-size: 38px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 53px;
          color: #2E2E2E;
        }
      }

      .el-divider--vertical {
        height: 88px;
        margin: 0 35px;
      }

      .el-divider {
        background-color: #CBCFD6;
      }
    }
  }
}

.service-div {
  width: 100%;
  height: 734px;
  background: url(../assets/img/bj3.jpg);
  background-position: center;
  background-repeat: no-repeat;

  .service-title {
    padding-top: 90px;
  }

  .step-div {
    width: 100%;
    background: url(../assets/img/arrow.png);
    background-position: center 163px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding-top: 56px;

    .step-card {
      width: 16%;

      .service-img-div {
        transition: all .2s ease;
      }

      .service-img-div:hover {
        transform: translateY(-15px);
      }

      .step-title-div {
        margin-bottom: 9px;
        text-align: left;
        padding-left: 8%;

        .serial-number-span {
          font-family: SourceHanSansCN-Bold;
          font-size: 24px;
          color: #346AEC;
          font-weight: 700;
        }

        .step-title {
          font-family: SourceHanSansCN-Medium;
          font-size: 22px;
          color: #2E2E2E;
          font-weight: 500;
          margin-left: 10px;
        }
      }

      .step-describe-div {
        text-align: left;
        padding-left: 8%;

        .step-describe {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          color: #585858;
          line-height: 34px;
          font-weight: 400;
          margin-left: 18px;
        }
      }

      .last-step-describe-div {
        padding-left: 0;
        width: 102%;
      }
    }

    .bottom-step-card {
      margin-top: 154px;
    }
  }
}

.experience-div {
  width: 100%;
  height: 262px;
  background: url(../assets/img/bj5.jpg);
  background-position: center;
  background-repeat: no-repeat;

  .experience-title {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: #FFFFFF;
    padding-top: 60px;
  }

  .further-div {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: #FFFFFF;
    margin-top: 20px;
  }
}

.jxd-bottom {
  width: 100%;
  height: 39px;
  box-sizing: border-box;
  color: #67707D;
  text-align: center;
  background-color: #2E2E2E;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  justify-content: center;

  span {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .record-no {
    cursor: pointer;

    .record-no-span {
      margin-left: 6px;
    }
  }
}

.jxd-contact {
  color: #fff;
}

.jxd-contact::before {
  content: '';
  display: inline-block;
  background-image: url(../assets/img/phone_white.gif);
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  margin-right: 4px;
}

/* 内容固定1280适配低分辨率屏幕 */
.main-width-top {
  height: 530px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* 内容固定1280适配低分辨率屏幕 */
.main-width {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.liner-btn {
  background: #186CF5;
  border-radius: 5px;
  width: 110px;
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;

}

::v-deep .el-button--primary:hover {
  color: #FFF;
  background-color: #1584F3;
  border-color: #1584F3;
}

.left-text {
  color: #fff;
  text-align: left;

  .left-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 49px;
    font-family: SourceHanSansCN-Bold;
    margin-top: 100px;
  }

  .left-bottom {
    font-size: 26px;
    line-height: 42px;
  }

  .liner-btn {
    color: #186CF5;
    background: #FFFFFF;
  }

  .liner-btn:hover {
    opacity: 0.9;
  }
}

.title {
  font-family: SourceHanSansCN-Bold;
  font-size: 34px;
  color: #2E2E2E;
  font-weight: 700;
}


.bottom-btn {
  text-align: center;
  margin-top: 58px;
}

.service-bottom-btn {
  margin-top: 40px;
}

.right-button-div {
  width: 81px;
  height: 229px;
  background: #196CF6;
  border-radius: 4px;
  position: fixed;
  bottom: 230px;
  right: 19px;
  padding-top: 13px;
  box-sizing: border-box;
  text-align: center;

  .free-experience-div {
    cursor: pointer;
  }

  .button-text {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 14px;
    color: #FFFFFF;
    margin-top: 5px;
  }

  .el-divider--horizontal {
    margin: 11px 12px;
    width: 56px;
  }
}

.arrow-div {
  position: fixed;
  right: 20px;
  bottom: 150px;
  cursor: pointer;
}

.phone-div {
  width: 187px;
  height: 81px;
  background: url(../assets/img/phone-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  bottom: 303px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .phone-text {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: #2377FF;
    margin-left: 5px;
    height: 20px;
  }
}

.qr-code-div {
  width: 187px;
  height: 211px;
  background: url(../assets/img/rectangle.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  bottom: 110px;
  right: 100px;
  padding-top: 27px;
  padding-left: 27px;
  box-sizing: border-box;

  .qr-code-text {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 14px;
    color: #707070;
    margin-top: 5px;
    position: relative;
    left: 8px;
  }
}

@media screen and (max-width: 480px) {
  .pc_page {
    display: none;
  }

  .app_page {
    display: block;
    padding-bottom: 56px;
    padding-top: 58px;
    position: relative;
    height: 100%;
  }

  .jxd-navbar {
    z-index: 10;
    width: 100%;
    background-color: #FFFFFF;
    padding: 12px 0 12px 20px;
    height: auto;
    position: fixed;
    top: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_box {
      display: flex;
      align-items: center;

      .nav-logo {
        float: left;

        a {
          display: flex;
          align-items: center;
        }
      }

      .stock-box {
        color: #2E2E2E;
        font-size: 11px;
        margin-left: 12px;
        float: left;
        text-align: left;
      }
    }
  }

  .app_content {
    height: 100%;

    .banner-div {
      height: 330px;
      background: url(../assets/img/app_adaptation/app_bj.png);
      background-size: cover;
      overflow: hidden;

      .banner {
        justify-content: center;
      }
    }

    .main-width-top {
      height: auto;
      width: 100%;

      .center-text {
        text-align: center;
        color: #FFFFFF;
        width: 100%;

        .left-title {
          margin: 37.5px 0 9px 0;
          font-size: 24px;
          text-align: center;
        }

        .left-bottom {
          color: #D6E7FF;
          font-size: 14px;
          text-align: center;
          padding-bottom: 27.5px;
        }

        .app_bdzy {
          display: block;

          img {
            //width: -webkit-fill-available;
            max-width: 375px;
            height: 221px;

            vertical-align: bottom;
          }
        }
      }
    }

    .pain-spot-div {
      height: auto;
      background-color: #F5F9FD;

      .product_content {
        width: 100%;
        padding: 8px 0 25px 0;

        .product_box {
          width: 30%;
          padding: 8px 0;
          display: flex;

          .polka_dot {
            margin-top: 4px;
            margin-right: 8px;
            height: 8px;
            width: 8px;
            border-radius: 25px;
            background-image: linear-gradient(rgba(84, 167, 248, 1), rgba(35, 119, 255, 1));
          }

          .product_text {
            font-size: 14px;
            color: #2E2E2E;
            text-align: left;
          }
        }
      }

      .title {
        font-size: 22px;
      }

      .pain-spot-title {
        padding-top: 50px;
        padding-bottom: 24.5px;
      }
    }

    .function-div {
      height: auto;
      padding-bottom: 20px;

      ::v-deep .el-tabs__nav {
        width: auto;
      }

      ::v-deep .el-tabs__item {
        width: auto;
      }

      .title {
        font-size: 22px;
      }

      .function-title {
        padding-top: 50px;
        padding-bottom: 24.5px;
      }

      .function-bottom {
        margin: auto;

        ::v-deep .van-tabs--line .van-tabs__wrap::before {
          content: '';
          height: 0.5px;
          background-color: #E4E7EC;
          width: 90%;
          z-index: 1;
          position: absolute;
          top: 80px;
          left: 15px;
          display: block;
        }

        ::v-deep .el-tabs__nav-wrap {
          height: 75px;
        }

        ::v-deep .el-tabs__nav {
          height: 75px;
        }

        //步骤条颜色
        ::v-deep .van-step--finish .van-step__line,
        ::v-deep .van-step__line {
          background-color: #CDDDF7 !important;
        }

        .tab_content {
          padding: 0 16px;
        }

        .tab-left {
          width: 100%;
          text-align: center;

          ::v-deep .van-steps--horizontal {
            padding: 10px 20px 0;
          }

          ::v-deep .van-steps--horizontal .van-steps__items {
            margin: -30px 0 20px;
          }

          ::v-deep .van-step--horizontal {
            display: flex;
          }

          ::v-deep .van-step__circle-container {
            background: center;
          }

          ::v-deep .van-step--horizontal .van-step__title {
            font-size: 14px;
            width: 40%;
            position: relative;
            bottom: -40px;
            right: -2px;
          }

          ::v-deep .van-step--horizontal:nth-child(2) .van-step__title {
            width: 60%;
          }

          ::v-deep .van-step--horizontal:first-child .van-step__title {
            left: -12px;
          }

          ::v-deep .van-step--horizontal:last-child .van-step__title {
            right: -75%;
          }

          ::v-deep .van-step--horizontal:last-child {
            width: 26%;
          }

          .function-introduce {
            text-align: left;
            font-size: 13px;
            padding: 17px 15px 19px 15px;
          }
        }

        .function_text {
          color: #585858;
          font-size: 15px;
          padding-top: 17px;
          padding-bottom: 22px;
        }

        .label-div {
          .tab-label {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }

    .more-div {
      height: auto;
      background: url(~@/assets/img/app_adaptation/app_cptx.png);
      background-size: cover;

      .title {
        font-size: 22px;
      }

      .more-title {
        padding-top: 50px;
        padding-bottom: 24.5px;
      }

      .more-card-parent {
        padding: 0 15px 20px 15px;
        margin: 0;
        height: auto;
        width: auto;

        .more-card:hover {
          background: rgba(255, 255, 255, 0.92);
          box-shadow: 0 0 0 0;
          transform: translateY(0);
        }

        .more-card {
          width: 49%;
          height: auto;
          text-align: center;
          margin-bottom: 9px;
          padding: 18px 0;

          .more-card-title {
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }

    .service-div {
      height: auto;

      .supply_box {
        width: 100%;
        display: flex;
        justify-content: center;
        width: 100%;

        ::v-deep .van-grid {
          margin: 18px 15px 38px 15px;
          border-radius: 2.5px;
          box-shadow: 0px 1.5px 10px 0px rgba(46, 66, 114, 0.08);
        }

        .step-title-div {
          margin-top: 9px;
          text-align: left;

          .step-title {
            font-size: 15px;
            color: #2E2E2E;
            font-weight: 500;
          }
        }

        .step-describe {
          font-size: 13px;
          color: #585858;
          font-weight: 400;
        }
      }

      .title {
        font-size: 22px;
      }

      .service-title {
        padding-top: 37.5px;
      }

      .service-content {
        font-size: 14px;
        padding: 15px 15px 0 15px;
        color: #2E2E2E;
        text-align: left;
      }
    }

    .experience-div {
      height: auto;
      background-size: cover;
      padding-bottom: 29px;

      .left-text {
        text-align: center;
        margin-top: 23px;

        .liner-btn {
          border: 0px;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .further-div {
        font-size: 16px;
        padding-top: 37px;
        margin: 0;
      }
    }

    .jxd-bottom {
      padding: 17px 15px;
      display: block;
      text-align: left;
      height: auto;

      .remark {
        font-size: 11px;
      }

      .connect_box {
        padding-bottom: 4px;

        .connect_text {
          color: #EEEEEE;
          font-size: 12px;

          img {
            vertical-align: sub;
          }
        }
      }

      .record-no {
        .record-no-span {
          margin: 0;
        }
      }
    }
  }

  .locate_btn {
    z-index: 10;
    position: fixed;
    bottom: 0;
    font-size: 15px;
    font-weight: 600;
    width: 100%;

    .phont_btn {
      width: 60%;
      background-color: #DCE9FF;
      color: #186CF5;
      padding: 20px 0;
    }

    .go_btn {
      width: 40%;
      background-image: linear-gradient(to right, #106CEC, #0191FF);
      color: #FFFFFF;
      padding: 20px 0;
    }
  }

  .flex-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

html,
body {
  height: 100%;
}
</style>
